footer {
	.sitemap-section {
		background: #404041;
		padding: 46px 0 47px;

		.__custom_row {
			max-width: 76rem;
		}

		.__full_center_left {
			display: flex;
			display: -webkit-flex;
			justify-content: flex-start;
		}

		.footer-logo {
			width: 220px;
			margin: 0 auto;
		}

		.footer-social-media {
			text-align: center;
			margin-top: 25px;

			ul {
				padding: 0;
				margin: 0;

				li {
					list-style: none;
					display: inline-block;
					margin-right: 4px;

					&:first-of-type {
						a {
							span {
								background: linear-gradient(45deg, #d1a12e, #dbaa2e 80%);
							}
						}
					}

					&:last-of-type {
						margin-right: 0;
					}

					a {
						text-decoration: none;

						span {
						    height: 48px;
						    width: 48px;
						    background: linear-gradient(45deg, #e4b32d, #edbc2d 80%);
						    border-radius: 50%;

						    i {
								color: #404041;
								font-size: 32px;
						    }
						}
					}
				}
			}
		}

		.footer-quick-links {
			h4 {
				font-size: 18px;
				font-family: 'MontserratBold';
				color: $white;
				letter-spacing: 0;
				line-height: normal;
			}

			hr {
				border-bottom: 2px solid #a2a2a2;
				margin: 13px 0 13px;
				max-width: 185px;
			}

			ul {
				padding: 0;
				margin: 0;

				li {
					list-style: none;
					display: block;
					padding-bottom: 1.5px;

					a {
						text-decoration: none;
						display: inline-block;
						text-transform: capitalize;
						color: $white;
						font-size: 15.5px;
						font-family: 'MontserratMedium';
						letter-spacing: 0;
					}
				}
			}
		}

		.footer-opening-hours {
			h4 {
				font-size: 18px;
				font-family: 'MontserratBold';
				color: $white;
				letter-spacing: 0;
				line-height: normal;
			}

			hr {
				border-bottom: 2px solid #a2a2a2;
				margin: 13px 0 13px;
				max-width: 185px;
			}

			p {
				font-size: 15.5px;
				font-family: 'MontserratMedium';
				color: $white;
				text-align: left;
				letter-spacing: 0;
				line-height: 1.8;

				&:last-of-type {
					font-size: 14px;
				}
			}
		}

		.footer-contact-details {
			position: relative;
			right: 1px;

			h4 {
				font-size: 18px;
				font-family: 'MontserratBold';
				color: $white;
				letter-spacing: 0;
				line-height: normal;
			}

			hr {
				border-bottom: 2px solid #a2a2a2;
				margin: 13px 0 13px;
				max-width: 185px;
			}

			p {
				font-size: 15.5px;
				font-family: 'MontserratMedium';
				color: $white;
				text-align: left;
				letter-spacing: 0;
				line-height: 1.8;

				a {
					text-decoration: none;
					color: $white;
					letter-spacing: 0;
				}
			}
		}

		.footer-subscribe-border {
			hr {
				border-bottom: 2px solid #a2a2a2;
				margin: 17px 0 20px;
				max-width: 100%;
			}
		}

		.footer-subscribe-wrapper {
		    display: flex;
		    justify-content: space-between;
		    align-items: flex-end;
		}

		.footer-subscribe-details {
			position: relative;
			top: 5px;

			h4 {
				font-size: 18px;
				font-family: 'MontserratBold';
				color: $white;
				letter-spacing: 0;
				line-height: normal;
			}

			hr {
				border-bottom: 2px solid #a2a2a2;
				margin: 13px 0 13px;
				max-width: 185px;
			}

			p {
				font-size: 15.5px;
				font-family: 'MontserratMedium';
				color: $white;
				text-align: left;
				letter-spacing: 0;
				line-height: 1.8;

				a {
					text-decoration: none;
					color: $white;
				}
			}
		}

		.footer-subscribe-btn {
			button {
				text-decoration: none;
				display: inline-block;
				font-size: 18px;
				font-family: 'MontserratBold';
				color: $white;
				border-style: solid;
				border-width: 1px;
				border-image: linear-gradient(150deg, #c2932f, #f9ca2a 65%) 1;
				padding: 12.5px 32.5px;
				letter-spacing: 0;
				line-height: normal;
				cursor: pointer;
			}
		}
	}

	.copyright-section {
		background: linear-gradient(45deg, #c6962f, #f7c72a 80%);
		padding: 12px 0;

		.__custom_row {
			max-width: 76rem;
		}

		.footer-copyright {
			p {
			    font-size: 15.5px;
			    font-family: 'CircularStdBook';
			    color: $dark_gray;
			    text-align: left;
			    letter-spacing: 0;
			}
		}

		.footer-designed {
			p {
			    font-size: 15.5px;
			    font-family: 'CircularStdBook';
			    color: $dark_gray;
			    text-align: right;
			    letter-spacing: 0;

			    a {
			    	text-decoration: none;
			    	color: $dark_gray;
			    	letter-spacing: 0;
			    }
			}
		}
	}
}
