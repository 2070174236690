$white: #ffffff;
$black: #000000;
$dark_gray: #231f20;
$light_gray: #58595b;


header {
	position: relative;

	.main-header-wrapper {
	    position: absolute;
	    top: 0;
	    width: 100%;
	    z-index: 99;
	}

	// .main-header-wrapper {
	//     -webkit-transition: all 0.3s;
	//     transition: all 0.3s;
	//     position: fixed;
	//     z-index: 1111;
	//     width: 100%;
	//     top: 0;
	//     right: 0;

	// 	&.__sticky {
	// 	    -webkit-box-shadow: 0 4px 13px rgba(0, 0, 0, .2);
	// 	    -moz-box-shadow: 0 4px 13px rgba(0, 0, 0, .2);
	// 	    box-shadow: 0 4px 13px rgba(0, 0, 0, .2);
	// 	}
	// }
	
	.header-logo-section {
		position: relative;
		background: transparent;
		padding: 30px 0 25px;

		.__custom_row {
			max-width: 77rem;
		}
	}

	.logo-social-media-wrapper {
		position: relative;
	}

	.header-logo {
	    text-align: center;
	    width: 124px;
	    margin: 0 auto;
	}

	.header-social-media {
		position: absolute;
		top: 12px;
		right: 0;

		p {
			span {
				&.__sm_title {
					font-size: 16px;
					font-family: 'MontserratSemiBold';
					color: $white;
					display: inline-block;
					border-right: 1px solid $white;
					padding-right: 18px;
					margin-right: 16px;
					line-height: 26px;
					letter-spacing: 0;
				}

				&.__sm_icon {
					display: inline-block;
					padding-right: 2px;

					&:last-of-type {
						padding-right: 0;

						a {
							i {
								position: relative;
								top: 1px;
							}
						}
					}

				    a {
				    	text-decoration: none;
				    	color: $white;
					    height: 22px;
					    width: 22px;
					    background: transparent;
					    border: 1px solid $white;
					    border-radius: 50%;
					    display: flex;
					    display: -webkit-flex;
					    align-items: center;
					    justify-content: center;

				    	i {
							color: $white;
							font-size: 14px;
				    	}
				    }
				}
			}
		}
	}

	.header-menu-section {
		position: relative;
		background: transparent;
		padding: 18px 0 23px;
		border-bottom: 2px solid #f1f3f4;

		.__custom_row {
			max-width: 77rem;
		}
	}

	/* first level menu */
	.header-main-menu {
		position: relative;
		text-align: center;

		ul {
			padding: 0;
			margin: 0;

			li {
				list-style: none;
				display: inline-block;
				border-right: 1px solid $white;
				padding: 0 12px;
				height: 26.4px;

				&:last-of-type {
					border-right: 0;
				}

				&:after {
					content: '';
					display: block;
					width: 0;
					height: 4px;
					background: $white;
					position: relative;
					top: 23px;
					z-index: 2;
	                -webkit-transition: width .30s ease-in-out;
	                -moz-transition: width .30s ease-in-out;
	                -o-transition: width .30s ease-in-out;
	                transition: width .30s ease-in-out;
				}

				&:hover {
					&:after {
						width: 100%;
					}
				}

				&.menu-item-has-children {
					a {
						&:after {
							content: '▼';
							color: $white;
						    font-size: 16.5px;
						    position: relative;
						    left: 5px;
						}
					}
				}

                &.current-menu-item {
					&:after {
						width: 100%;
					}
                }

                &.current-menu-parent {
					&:after {
						width: 100%;
					}
                }

                a {
					text-decoration: none;
					display: inline-block;
					text-transform: capitalize;
					color: $white;
					font-size: 16.5px;
					font-family: 'MontserratMedium';
					letter-spacing: 0;
					padding: 0 15px;
					position: relative;
					top: 2px;
                }
			}
		}
	}

	/* second level menu */
    .header-main-menu {
        li {
            &.menu-item-has-children {
                ul {
                    li {
                        a {
                            color: $white;
                            text-transform: none;
							padding: 0;
							position: relative;
							top: 0;
                        }

                        &.current-menu-item {
                            a {
                                color: #f9c92a;
                            }
                        }
                    }
                }
            }

            > ul {
                // position: absolute;
                // z-index: 12;
                // margin-top: 30px;
                // background: transparent;
                
				position: absolute;
				z-index: 12;
				margin-top: 30px;
				background: rgba(0, 0, 0, 0.6);
				padding: 0 20px 20px;

                li {
                    list-style: none;
                    display: block;
                    padding: 8px 0 4px;
                    margin: 0;
                    text-align: left;
					height: auto;
					border-right: 0;
					border-bottom: 1.5px solid #f1f3f4;

                    &:after {
                    	content: none;
                    }

                    a {
						text-align: left;

                        &:hover {
                            color: #f9c92a;
                        } 

	                    &:after {
	                    	content: none !important;
	                    }                     
                    }
                }
            }
        }
    }


    /** page image banner **/
	.page-banner-wrapper {
		position: relative;
		background: $black;
		height: 855px;

		&.__medium_banner {
			height: 560px;
		}

		.page-banner {
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0.7;
		}
	}

	/** page image slider banner **/
	.page-slider-banner-wrapper {
		position: relative;
		background: $black;

        .banner-image {
            position: relative;
            margin: 0 auto;
            width: 100%;
            max-width: 100%;
            height: 855px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

			&.__medium_banner {
				height: 560px;
			}
        }

        .slider-content-container {
        	position: absolute;
        	bottom: 56px;
        	left: 168px;
        	display: flex;
        	background: $white;
		    -webkit-box-shadow: 4px 4px 13px rgba(0, 0, 0, 0.55);
		    -moz-box-shadow: 4px 4px 13px rgba(0, 0, 0, 0.55);
		    box-shadow: 4px 4px 13px rgba(0, 0, 0, 0.55);
		    z-index: 11;
        }

        .slider-content {
        	padding: 25px 85px 20px 25px;

        	p {
        		font-size: 18px;
        		font-family: 'MontserratBlack';
        		color: $dark_gray;
        		line-height: 1.2;
        		letter-spacing: 0;
        	}
        }

        .slider-btn {
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    padding-right: 30px;

		    img {
		    	width: 62px;
		    	height: 62px;
		    }
        }

        .banner-image-overlay {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.3);
			width: 100%;
			height: 100%;
			z-index: 10;
        }
	}

	/** page video banner **/
	.page-video-banner {
		.__video_wrapper {
			position: relative;

			.__video_overlay {
			    position: absolute;
	            top: 0;
	            right: 0;
	            bottom: 0;
	            left: 0;
			    width: 100%;
			    height: 100%;
			    background: rgba(0, 0, 0, 0.2);
			    z-index: 11;
			    text-align: center;
			}

			.responsive-embed {
			    padding-bottom: 56%;
			    background-color: $black;

			    &:after {
					content: 'Loading Video...';
					position: absolute;
					width: 100%;
					text-align: center;
					color: $white;
					top: 50%;
					transform: translateY(-50%);
			    }

			    iframe {
			    	z-index: 1;
			    }
			}
		}
	}


	/** mobile menu responsive **/
	.__mobile_logo_menu {
		display: none;
	}

	.mobile-main-menu {
        position: relative;

        a > img {
            width: 124px;
        }

        #open-mobile-menu {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            i.fa.fa-bars {
                cursor: pointer;
                font-size: 32px;
                color: #f0bf2c;
                padding: 4px 6px;
                border: 2px solid #f0bf2c;
                border-radius: 4px;
                background: transparent;
            }
        }

        #mobile-menu {
            display: none;
        }
	}
}


/** moby mobile menu **/
.moby {
    overflow: unset;

    &.left-side {
        min-width: 350px;
        left: -1px;
    }

    .moby-close {
        padding: 0;
        position: absolute;
        background: transparent;
        right: 0;
        top: 5px;

        .moby-close-icon {
            height: 40px;
            width: 40px;

            &:before, &:after {
                background: $white;
            }
        }
    }

    &.moby-active {
        .moby-close {
            right: -55px;
        }
    }

    .moby-wrap {
        overflow-y: auto;
        height: 100%;
        background: $white;

        .__mobile_side_logo {
            max-width: 125px;
            margin: 30px auto;
        }

		.__mobile_social_media {
			position: relative;
			text-align: center;
			margin-bottom: 30px;

			p {
				span {
					&.__sm_title {
						font-size: 16px;
						font-family: 'MontserratSemiBold';
						color: $black;
						display: inline-block;
						border-right: 1px solid $black;
						padding-right: 18px;
						margin-right: 16px;
						line-height: 26px;
						letter-spacing: 0;
					}

					&.__sm_icon {
						display: inline-block;
						padding-right: 2px;

						&:last-of-type {
							padding-right: 0;

							a {
								i {
									position: relative;
									top: 1px;
								}
							}
						}

					    a {
					    	text-decoration: none;
					    	color: $black;
						    height: 24px;
						    width: 24px;
						    background: transparent;
						    border: 1px solid $black;
						    border-radius: 50%;
						    display: flex;
						    display: -webkit-flex;
						    align-items: center;
						    justify-content: center;

					    	i {
								color: $black;
								font-size: 16px;
					    	}
					    }
					}
				}
			}
		}

        .menu-main-menu-container {
            ul {
                padding: 0;
                margin: 0;

                > li {
                    &.current-menu-item {
                        a {
                            color: #f9c92a;
                        }
                    }

                    &.current-menu-parent {
                        a {
                            color: #f9c92a;
                        }
                    }

                    > a {
                        padding: 0;
                        color: $black;
                        font-size: 16px;
                        font-family: 'MontserratSemiBold';
                        letter-spacing: 0;
                        padding: 16px 15px;
                        border-top: 1px solid $black;
                        text-transform: uppercase;

                        &:hover {
                            color: #f9c92a;
                            -webkit-transition: all .30s ease-in-out;
                            -moz-transition: all .30s ease-in-out;
                            -o-transition: all .30s ease-in-out;
                            transition: all .30s ease-in-out;
                        }

                        .moby-expand {
                            color: $black;
                        }
                    }
                    
                    &:last-of-type {
                        a {
                            border-bottom: 1px solid $black;
                        }
                    }
                }

                li {
                    &.menu-item-has-children {
                        ul {
                            border-top: 1px solid $black;
                            padding: 10px 0 10px 15px;

                            li {
                                padding: 8px 0;

                                a {
                                    display: table;
                                    padding: 0 0 5px;
                                    border-bottom: 1px solid transparent;
                                    border-top: 0;
                                    color: $black;

                                    &:hover {
                                        color: #f9c92a;
                                        -webkit-transition: all .30s ease-in-out;
                                        -moz-transition: all .30s ease-in-out;
                                        -o-transition: all .30s ease-in-out;
                                        transition: all .30s ease-in-out;
                                    }
                                }

                                &.current-menu-item {
                                    a {
                                        color: #f9c92a;
                                    }
                                }
                            }
                        }
                    }

                    &:last-of-type {
                        &.menu-item-has-children {
                            ul {
                                border-top: 0;
                                border-bottom: 1px solid $black;
                            } 
                        }
                    }
                }
            }
        }
    }
}
