/*********************
* Global Stylesheets *
**********************/

/* --> Mixins */
@mixin d_inline_block( $alignment ) {
  display: inline-block;
  vertical-align: $alignment
}
@mixin transition( $effects ) {
  -webkit-transition: $effects;
  transition: $effects;
}
@mixin p_absolute( $alignment ) {
  position: absolute;
  @if $alignment == top {
    top: 0;
  } @else if $alignment == middle {
    top: 50%;
    transform: translateY(-50%);
  } @else if $alighment == bottom {
    bottom: 0;
  }
}
@mixin background_image( $img_url ) {
  background: $img_url center/cover no-repeat;
}
@mixin background_image_center() {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
/* <--- Mixins */

/* --> HTML Tag */
* {
  image-rendering: -webkit-optimize-contrast;
}

// ::selection {
//   background: $light-primary-color; /* WebKit/Blink Browsers */
// }
// ::-moz-selection {
//   background: $light-primary-color; /* Gecko Browsers */
// }

body {
  background: $white;

  p {
    // font-size: 14px;
    // font-family: 'OpenSansSemiBold';
    // color: #645d5d;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
.responsive-embed {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  line-height: 1;
  letter-spacing: 2px;
}
p,
a {
  letter-spacing: 0.5px;
}
p {
  line-height: 1.4;
}
a,
input,
button,
textarea,
select {
  outline: none !important;
  @include transition( all .3s );
}

a {
  &:hover {
    text-decoration: underline;
  }
}

h3 {
  font-size: 20px;
}

figure {
  margin: 0;
}

table {
  @include breakpoint( small down ) {
    &.__timeline {
      td {
        margin-bottom: 0;
        padding: 0 !important;
        &:last-child {
          margin-bottom: 15px;
        }
      }
    }
    tr,
    td {
      height: auto !important;
    }
    td {
      // width: 100% !important;
      // display: block;
      // margin-bottom: 15px;
    }
  }
}
.__table_overflow {
  overflow: auto;
  @media ( max-width: 650px ) {
    table {
      width: 690px !important;
      td {
        width: 170px !important;
        display: inline-block;
        margin: 0;
      }
    }
  }
}
/* <-- HTML Tag */

/* --> DEV Class */
.__preloader {
  background: $primary-color;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 11111;
  &--icon {
    margin-left: 5px;
    max-width: 60px;
  }
  .__table--cell_middle {
    padding: 0 50px;
    text-align: center;
  }
}
.__full_center {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}
.__show_grid {
  margin-bottom: 15px;
}
.__show_grid_2 {
  margin-bottom: 20px;
}
.__show_grid_3 {
  margin-bottom: 30px;
}
.__show_grid_4 {
  margin-bottom: 40px;
}
.justify-content-center {
  justify-content: center;
}
.__loading {
  overflow: hidden;
}

.__no_padding {
  padding: 0;
}
.__no_padding_right {
    padding-right: 0;
}
.__no_padding_left {
    padding-left: 0;
}
.__no_margin {
  margin: 0;
}

.__d_table {
  display: table;
  width: 100%;
  height: 100%;
  &--cell_middle {
    display: table-cell;
    vertical-align: middle;
  }
}

.__row_extended {
  max-width: 90rem !important;
}
.__full_width {
  max-width: 100%;
  .columns {
    padding-left: 0;
    padding-right: 0;
  }
}
.__no_gutter {
  max-width: 95rem;
  margin: auto;
}

.__dark_overlay,
.__light_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.__dark_overlay {
  background-color: rgba(0, 0, 0, 0.3);
  @include transition( all .3s );
  &.__link,
  &.__view {
    @include background_image_center();
  }
  &.__link {
    background-image: url(../images/link.png);
    background-size: 32px !important;
  }
  &.__view {
    background-image: url(../images/eye.png);
    background-size: 42px !important;
  }
}
.__light_overlay {
  background-color: rgba(255, 255, 255, 0.3);
}

.__p_relative {
  position: relative;
}

.__no_found {
  padding: 100px 0;
  text-align: center;
  font-size: 30px;
}
/* <-- DEV Class */

/* --> THIRD PARTY Class */
// Foundation
.column,
.columns {
  position: relative;
  &:last-child:not(:first-child) {
    float: left;
  }
}
.reveal {
  border-radius: 5px;
  position: relative;
  padding: 35px;
  .close-button {
    position: absolute;
  }
}

// Visual Editor
.alignright,
.aligncenter {
  display: block;
}
.aligncenter {
  margin-right: auto;
  margin-left: auto;
}
.alignright {
  margin-right: 0;
  margin-left: auto;
}

// Hamburger
.hamburger {
  outline: none;
  padding: 0;
  @include transition( all .3s );
  @include d_inline_block( middle );
  &:hover {
    opacity: 1;
    .hamburger-box {
      .hamburger-inner {
        background-color: $white;
        &:before,
        &:after {
          background-color: $white;
        }
      }
    }
  }
  &.is-active {
    &:hover {
      opacity: 1;
    }
    .hamburger-box {
      .hamburger-inner {
        background-color: $white;
        &:before,
        &:after {
          background-color: $white;
        }
      }
    }
  }
  .hamburger-box {
    .hamburger-inner {
      background-color: $white;
      border-radius: 0;
      @include transition( all .3s );
      &:before,
      &:after {
        background-color: $white;
        border-radius: 0;
        @include transition( all .3s );
      }
    }
  }
}

// Contact Form 7
.wpcf7-not-valid {
  border-color: red !important;
}
.wpcf7-not-valid-tip {
  font-size: 13px !important;
  color: red !important;
}
.wpcf7-response-output {
  padding: 15px !important;
  margin: 0;
  margin: 10px 0 !important;
  color: #fff;
  border: 0 !important;
  font-size: 16px;
  &.wpcf7-mail-sent-ok {
    background: #4caf50;
  }
  &.wpcf7-mail-sent-ng {
    background: #f44336;
  }
  &.wpcf7-spam-blocked {
    background: #ff9800;
  }
  &.wpcf7-validation-errors {
    background: #e5d92e;
  }
}
.wpcf7-submit {
  display: inline-block !important;
}
.grecaptcha-badge {
  visibility: hidden;
}

.wpcf7 form.invalid .wpcf7-response-output, 
.wpcf7 form.unaccepted .wpcf7-response-output {
  background: #e5d92e;
}

.wpcf7 form.sent .wpcf7-response-output {
  background: #4caf50;
}
/* <-- THIRD PARTY Class */