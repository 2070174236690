.__content_missing {
	background: $white;
	
	.__page_banner {
		background-color: $dark-gray;
	}

	p {
		padding: 160px 0;
		text-align: center;
		font-size: 36px;
		font-family: 'MontserratSemiBold';
		color: $dark_gray;
		letter-spacing: 0;
	}
}