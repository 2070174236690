/******************************************************************

Stylesheet: Main Stylesheet

Here's where the magic happens. Here is where you import
all of your Sass files so they can compile into one
CSS file.

******************************************************************/

// Import local Foundation overrides - Don't link to Bower version as it will be overwritten whenever updated
@import "tinymce"; // Setting of fonts and anything under the visual editor
@import "settings";

// Import foundation.scss from vendor files
@import "../../../node_modules/foundation-sites/scss/foundation";
@import "../../../node_modules/hamburgers/_sass/hamburgers/hamburgers";
@import "../../../node_modules/swiper/css/swiper";

// Import Motion-UI from vendor files
@import "../../../node_modules/motion-ui/src/motion-ui";
@include motion-ui-transitions;
@include motion-ui-animations;

// Select the components you want to use
@include foundation-global-styles;

//Select the grid you would like to use
@include foundation-grid;

@include foundation-flex-grid;
@include foundation-flex-classes;

//@include foundation-xy-grid-classes;

@include foundation-typography;
// @include foundation-forms;
// @include foundation-button;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-card;
@include foundation-close-button;
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

// Import Daily Press custom style
@import "partials";