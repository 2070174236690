main {
	/** Homepage **/
	&.home {
		.welcome-section {
			background: #f3f3f4;
			padding: 75px 0;

			.__custom_row {
				max-width: 76rem;
			}

			.welcome-content {
				h2 {
					font-size: 44px;
					font-family: 'MontserratExtraBold';
					color: $dark_gray;
					letter-spacing: 0;
					line-height: 1.07;
					margin-bottom: 15px;
					margin-top: 5px;
					//margin-top: 20px;

					span {
						background: linear-gradient(45deg, #c2932f, #f2c12c 80%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
				}

				p {
					font-size: 16px;
					font-family: 'MontserratMedium';
					color: $light_gray;
					text-align: left;
					letter-spacing: 0;
					line-height: 1.7;
				}
			}

			.welcome-image {
				img {
					width: 100%;
				}
			}
		}

		.promotions-section {
			background: $white;
			padding: 80px 0 60px;

			.__custom_padding {
				padding: 0 7px;
			}

			.__flex_bottom_left {
				display: flex;
				display: -webkit-flex;
				align-items: flex-end;
				justify-content: flex-start;
			}

			.promotion-image {
				position: relative;
				margin: 0 auto;
				width: 100%;
				max-width: 100%;
				height: 440px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				-webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, .2);
				-moz-box-shadow: 0 3px 12px rgba(0, 0, 0, .2);
				box-shadow: 0 3px 12px rgba(0, 0, 0, .2);
			}

			.promotion-image-title {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				h4 {
					text-align: center;
					background: linear-gradient(100deg, #c6962f, #f9c92a 65%);
					font-size: 16px;
					font-family: 'MontserratBlack';
					color: $dark_gray;
					text-transform: uppercase;
					display: inline-block;
					letter-spacing: 0;
					line-height: normal;
					padding: 12px 15px;
					width: 115px;
				}
			}

			.promotion-title {
				border: 1px solid #a7a9ac;
				border-top: 0;
				padding: 28px 25px 33px;

				h2 {
					font-size: 23.5px;
					font-family: 'MontserratExtraBold';
					color: $dark_gray;
					text-transform: uppercase;
					letter-spacing: 0;
					line-height: 1.25;
				}

				a {
					text-decoration: none;
					display: inline-block;
					font-size: 16px;
					font-family: 'MontserratSemiBold';
					color: $dark_gray;
					letter-spacing: 0;
					background: linear-gradient(150deg, #c6962f, #f9c92a 65%);
					padding: 5px 29.25px;
					border-radius: 20px;
					margin-top: 15px;
				}
			}
		}

		.whats-on-section {
			background: #f3f3f4;
			padding: 80px 0 80px;

			.__custom_row {
				max-width: 76rem;
			}

			.whats-on-title {
				h2 {
					text-align: center;
				    font-size: 44px;
				    font-family: 'MontserratExtraBold';
				    color: $dark_gray;
				    letter-spacing: 0;
				    line-height: normal;
				    margin-bottom: 40px;

				    span {
				    	color: #f4c32b;
				    }
				}
			}

			.whats-on-slider {
				position: relative;
				width: 100%;
				clear: both;
			}

			.event-image {
				position: relative;
			    margin: 0 auto;
			    width: 100%;
			    max-width: 100%;
			    height: 290px;
			    background-repeat: no-repeat;
			    background-position: center;
			    background-size: cover;
			}

			.event-details {
				text-align: center;
			    border: 1px solid #a7a9ac;
			    border-top: 0;
			    padding: 22px 0 20px;

			    h2 {
					font-size: 16px;
					font-family: 'MontserratBlack';
					color: $dark_gray;
					text-transform: uppercase;
					letter-spacing: 0;
					line-height: normal;
					margin-bottom: 15px;
			    }
			}

			.event-buttons {
			    display: flex;
			    justify-content: center;
			    align-items: center;
			    text-align: center;
			    padding: 0 25px;

			    a {
	    			text-decoration: none;
	    			display: inline-block;
					font-size: 10px;
					font-family: 'MontserratBold';
					color: $dark_gray;
					letter-spacing: 0;
					background: linear-gradient(150deg, #c6962f, #f9c92a 65%);
					padding: 5px 10px;
					border-radius: 20px;
					width: 50%;
					margin-right: 7px;

					&:last-of-type {
						margin-right: 0;
					}
			    }
			}

			.swiper-custom-navigation {
				width: 100%;
				text-align: center;
				position: absolute;
				top: 155px;

				.swiper-btn-prev {
					outline: none;
					cursor: pointer;
					position: absolute;
					left: -90px;

					img {
						width: 34px;
						height: 34px;
					}
				}

				.swiper-btn-next {
					outline: none;
					cursor: pointer;
					position: absolute;
					right: -90px;

					img {
						width: 34px;
						height: 34px;
					}					
				}
			}

			.no-events-found {
				text-align: center;

				h2 {
					font-family: 'MontserratSemiBold';
					font-size: 32px;
					color: #f4c32b;
					letter-spacing: 0;
					line-height: normal;
					margin-bottom: 30px;
				}
			}
		}

		.home-gallery-section {
			background: $white;
			padding: 61px 0 94px;

			.__inner_padding {
				padding: 0 7.2px;
			}

			.__m_bottom_large {
				margin-bottom: 15px !important;
			}

			.__m_bottom_small {
				margin-bottom: 15px !important;
			}

			.gallery-title {
				h2 {
					text-align: center;
				    font-size: 44px;
				    font-family: 'MontserratExtraBold';
				    color: $dark_gray;
				    letter-spacing: 0;
				    line-height: normal;
				    margin-bottom: 40px;
				}
			}

			.gallery-image-large {
				position: relative;
				margin: 0 auto;
				width: 100%;
				max-width: 100%;
				height: 320px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
			}

			.gallery-image-small {
				position: relative;
				margin: 0 auto;
				width: 100%;
				max-width: 100%;
				height: 210px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
			}
		}
	}	
}



/** Content Editor Layout **/
.content-editor-section {
	background: #f3f3f4;
	padding: 75px 0 70px;

	.content-editor-full {
		text-align: center;

		h2 {
			font-size: 44px;
			font-family: 'MontserratExtraBold';
			color: $dark_gray;
			letter-spacing: 0;
			line-height: normal;
			margin-bottom: 10px;
		}

		p {
			font-size: 16px;
			font-family: 'MontserratMedium';
			color: $light_gray;
			letter-spacing: 0;

			a {
				text-decoration: none;
				color: $light_gray;
				letter-spacing: 0;				
			}
		}
	}
}
/** Content Editor Layout **/


/** Content And Media Editor Layout **/
.content-and-media-section {
	background: #f3f3f4;
	padding: 75px 0 85px;

	.__custom_row {
		max-width: 76rem;
	}

	.cam-content {
		h2 {
			font-size: 44px;
			font-family: 'MontserratExtraBold';
			color: $dark_gray;
			letter-spacing: 0;
			line-height: 1.07;
			margin-bottom: 15px;

			span {
				background: linear-gradient(45deg, #c2932f, #f2c12c 80%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		p {
			font-size: 16px;
			font-family: 'MontserratMedium';
			color: $light_gray;
			text-align: left;
			letter-spacing: 0;
			line-height: 1.5;

			a {
				text-decoration: none;
				color: $light_gray;
				letter-spacing: 0;				
			}
		}
	}

	.cam-image {
		img {
			width: 100%;
		}
	}
}
/** Content And Media Editor Layout **/


/** Image Tiles Layout **/
.image-tiles-section{
	background: $white;
	padding: 96px 0 60px;

	.__tile_padding {
		padding: 0 7px;
	}

	.__flex_bottom_left {
		display: flex;
		display: -webkit-flex;
		align-items: flex-end;
		justify-content: flex-start;
	}

	.image-tile-item {
		margin-bottom: 40px;
	}

	.item-tile-image {
		position: relative;
		margin: 0 auto;
		width: 100%;
		max-width: 100%;
		height: 440px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		-webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, .2);
		-moz-box-shadow: 0 3px 12px rgba(0, 0, 0, .2);
		box-shadow: 0 3px 12px rgba(0, 0, 0, .2);
	}

	.item-tile-image-title {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		h4 {
			text-align: center;
			background: linear-gradient(100deg, #c6962f, #f9c92a 65%);
			font-size: 16px;
			font-family: 'MontserratBlack';
			color: $dark_gray;
			text-transform: uppercase;
			display: inline-block;
			letter-spacing: 0;
			line-height: normal;
			padding: 12px 20px;
		}
	}

	.item-tile-content {
		border: 1px solid #a7a9ac;
		border-top: 0;
		padding: 28px 23px 25px;

		h2 {
			font-size: 23.5px;
			font-family: 'MontserratExtraBold';
			color: $dark_gray;
			text-transform: uppercase;
			letter-spacing: 0;
			line-height: 1.25;
			margin-bottom: 13px;
		}

		p {
			font-size: 13.5px;
			font-family: 'MontserratMedium';
			color: $light_gray;
			text-align: left;
			line-height: 1.5;
			letter-spacing: 0;
		}

		a {
			text-decoration: none;
			display: inline-block;
			font-size: 16px;
			font-family: 'MontserratSemiBold';
			color: $dark_gray;
			letter-spacing: 0;
			background: linear-gradient(150deg, #c6962f, #f9c92a 65%);
			padding: 5px 29.25px;
			border-radius: 20px;
			margin-top: 15px;
		}
	}
}
/** Image Tiles Layout **/


/** Gallery Layout **/
.gallery-section {
	background: #f3f3f4;
	padding: 61px 0 94px;

	.__inner_padding {
		padding: 0 7.2px;
	}

	.__m_bottom_large {
		margin-bottom: 15px !important;
	}

	.__m_bottom_small {
		margin-bottom: 15px !important;
	}

	.gallery-title {
		h2 {
			text-align: center;
		    font-size: 44px;
		    font-family: 'MontserratExtraBold';
		    color: $dark_gray;
		    letter-spacing: 0;
		    line-height: normal;
		    margin-bottom: 40px;
		}
	}

	.gallery-image-large {
		position: relative;
		margin: 0 auto;
		width: 100%;
		max-width: 100%;
		height: 320px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	.gallery-image-small {
		position: relative;
		margin: 0 auto;
		width: 100%;
		max-width: 100%;
		height: 210px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
}
/** Gallery Layout **/


/** Gallery Menu & Details Layout **/
.gallery-menu-details-section {
	background: $white;
	padding: 82px 0 70px;

	.__custom_padding {
		padding: 0 7px;
	}

	.gallery-image-item {
		position: relative;
		margin: 0 auto;
		width: 100%;
		max-width: 100%;
		height: 440px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		-webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, .2);
		-moz-box-shadow: 0 3px 12px rgba(0, 0, 0, .2);
		box-shadow: 0 3px 12px rgba(0, 0, 0, .2);
		margin-bottom: 13.5px;
	}

	.menu-content {
		position: relative;
		margin: 0 auto 18px;
		width: 100%;
		max-width: 100%;
		// height: 211px;
		height: auto;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		-webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, .2);
		-moz-box-shadow: 0 3px 12px rgba(0, 0, 0, .2);
		box-shadow: 0 3px 12px rgba(0, 0, 0, .2);		
	}

	.menu-content-overlay {
		position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    background: rgba(0, 0, 0, 0.68);

	    &.__bg_black {
	    	background: $black;
	    }
	}

	.menu-content-container {
		position: relative;
	    padding: 28px 42.5px 27.6px;

	    a {
	    	text-decoration: none;
	    	color: $white;
		}

	    h4 {
		    display: flex;
		    justify-content: space-between;
		    font-size: 23.5px;
		    font-family: 'MontserratExtraBold';
		    color: $white;
		    text-transform: uppercase;
		    letter-spacing: 0;
		    line-height: 1.2;
		    margin-bottom: 25px;

		    span {
		    	position: relative;
				top: -0.5px;
				left: 9.8px;
			    display: flex;
			    display: -webkit-flex;
			    align-items: center;
			    justify-content: space-between;
		    }
	    }

		p {
		    font-size: 16px;
		    font-family: 'MontserratMedium';
		    color: $white;
		    letter-spacing: 0;
		    line-height: 1.55;
		}
	}

	.details-content {
		background: $black;
		width: 100%;
		// height: 211px;
		// padding: 20px 42.5px;
		padding: 20px 42.5px 16.4px;
		-webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, .2);
		-moz-box-shadow: 0 3px 12px rgba(0, 0, 0, .2);
		box-shadow: 0 3px 12px rgba(0, 0, 0, .2);

		h4 {
		    font-size: 16px;
		    font-family: 'MontserratExtraBold';
		    color: $white;
			background: linear-gradient(45deg, #f6dd79, #b98732 30%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		    text-transform: uppercase;
		    letter-spacing: 0;
		    line-height: normal;
		    margin-bottom: 1.5px;
		}

		p {
		    font-size: 16px;
		    font-family: 'MontserratMedium';
		    color: $white;
		    letter-spacing: 0;
		    line-height: 1.55;

		    a {
		    	text-decoration: none;
		    	color: $white;
		    	letter-spacing: 0;
		    }
		}

		hr {
			border-bottom: 1px solid $white;
    		border-image: linear-gradient(45deg, #f6dd79, #b98732 20%) 1;
			margin: 15px 0 18px;
			max-width: 100%;
		}
	}
}
/** Gallery Menu & Details Layout **/


/** Events List Layout **/
.events-list-section {
	background: $white;
	padding: 56px 0 30px;

	.__event_padding {
		padding: 0 7.2px;
	}

	.__show_grid {
		margin-bottom: 13.8px;
	}

	.__flex_bottom_left {
		display: flex;
		display: -webkit-flex;
		align-items: flex-end;
		justify-content: flex-start;
	}

	.event-image {
	    position: relative;
	    margin: 0 auto;
	    width: 100%;
	    max-width: 100%;
	    height: 325px;
	    background-repeat: no-repeat;
	    background-position: center;
	    background-size: cover;
		-webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, .2);
		-moz-box-shadow: 0 3px 12px rgba(0, 0, 0, .2);
		box-shadow: 0 3px 12px rgba(0, 0, 0, .2);
	}

	.event-image-title {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		h4 {
			text-align: center;
			background: linear-gradient(100deg, #c6962f, #f9c92a 65%);
			font-size: 16px;
			font-family: 'MontserratBlack';
			color: $dark_gray;
			text-transform: uppercase;
			display: inline-block;
			letter-spacing: 0;
			line-height: normal;
			padding: 12px 20px;
		}
	}

	.event-details {
		text-align: center;
		border: 1px solid #a7a9ac;
		border-top: 0;
		padding: 28px 20px 25px;
		// padding: 28px 0 25px;

		h2 {
			font-size: 23.5px;
			font-family: 'MontserratExtraBold';
			color: $dark_gray;
			text-transform: uppercase;
			letter-spacing: 0;
			line-height: 1.25;
			margin-bottom: 10px;
		}

		p {
		    font-size: 16px;
		    font-family: 'MontserratMedium';
		    color: $light_gray;
		    line-height: 1.3;
		    letter-spacing: 0;
		}
	}

	.event-buttons {
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    text-align: center;		
		margin-top: 20px;

		a {
			text-decoration: none;
			display: inline-block;
			font-size: 16px;
			font-family: 'MontserratSemiBold';
			color: $dark_gray;
			letter-spacing: 0;
			background: linear-gradient(150deg, #c6962f, #f9c92a 65%);
			padding: 5px 15px;
			border-radius: 20px;
			width: 195.5px;
			margin-right: 7px;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	.no-events-found {
		text-align: center;

		h2 {
			font-family: 'MontserratSemiBold';
			font-size: 32px;
			color: #f4c32b;
			letter-spacing: 0;
			line-height: normal;
			margin: 30px 0;
		}
	}
}

/* single page */
#tribe-events-pg-template {
	max-width: 100%;
	padding: 0;
	margin: 0;
}

#tribe-events {
	padding: 0;
}
/** Events List Layout **/


/** Gravity Form Layout **/
.gf-form-section {
	background: #f3f3f4;
	padding: 68px 0 70px;

	.gf-contact-form-title {
		text-align: center;
		margin-bottom: 20px;

		h2 {
		    font-size: 44px;
		    font-family: 'MontserratExtraBold';
		    color: $dark_gray;
		    letter-spacing: 0;
		    line-height: normal;
		    margin-bottom: 15px;
		}

		p {
		    font-size: 16px;
		    font-family: 'MontserratMedium';
		    color: $light_gray;
		    letter-spacing: 0;
		}
	}

	.gf-contact-form {
		.__gf_margin_bottom {
			margin-bottom: 30px;
		}

		.__gf_margin_bottom_12 {
			margin-bottom: 12px;
		}

		.__gf_margin_top {
			margin-top: 50px;
		}

		.__gf_margin_top_20 {
			margin-top: 20px;

			p {
				font-size: 14px !important;
			}
		}

		.__gf_margin_top_40 {
			margin-top: 40px;
		}

		.gform_fields {
			grid-column-gap: 20.8px !important;
			grid-row-gap: 17px !important;
		}
		
        input.large, input.datepicker, select.large, textarea.large, input[type="text"] {
            width: 100% !important;
            height: 46px;
			background: #e9e8e8;
            color: $black;
            padding: 5px 18px !important;
            border: none;
            font-family: 'MontserratMedium';
            font-size: 15px !important;
            letter-spacing: 0;

            &::-webkit-input-placeholder {
                color: $black;
                font-family: 'MontserratMedium';
                text-transform: uppercase;
            }
            &:-ms-input-placeholder {
                color: $black;
                font-family: 'MontserratMedium';
                text-transform: uppercase;
            }
            &::-moz-placeholder {
                color: $black;
                font-family: 'MontserratMedium';
                text-transform: uppercase;
            }
            &:-moz-placeholder {
                color: $black;
                font-family: 'MontserratMedium';
                text-transform: uppercase;
            }  
        }

        .ginput_container_textarea {
	    	height: 200px;
	    }

        textarea.textarea {
		    height: 200px !important;
		    padding: 15px 18px !important;
		    resize: none;
        }

        select.large {
	        -webkit-appearance: none;
	        -moz-appearance: none;
	        -o-appearance: none;
	        background-image: url(../images/arrow-dd.png);
	        background-repeat: no-repeat;
	        background-position: 98% center;
        }

    	label.gfield_label {
		    font-size: 15px !important;
		    font-family: 'MontserratMedium';
		    color: $dark_gray;
		    letter-spacing: 0;
    	}

        .gfield_html{
        	h3 {
				font-size: 24px;
				font-family: 'MontserratExtraBold';
				color: $dark_gray;
				letter-spacing: 0;
				margin-bottom: 10px;
        	}

        	p {
				font-size: 15px;
				font-family: 'MontserratMedium';
				font-style: italic;
				color: $light_gray;
				letter-spacing: 0;
        	}
        }

        .gsection {
        	border-bottom: 1px solid $dark_gray !important;
        	margin-bottom: 40px;
        }

        .gfield_radio {
        	label {
			    font-size: 15px !important;
			    font-family: 'MontserratMedium';
			    color: $dark_gray;
			    letter-spacing: 0;
        	}
        }

        .gfield_checkbox {
        	label {
			    font-size: 15px !important;
			    font-family: 'MontserratMedium';
			    color: $dark_gray;
			    letter-spacing: 0;
        	}
        }

        .gfield_price {
        	label {
			    font-size: 15px !important;
			    font-family: 'MontserratMedium';
			    color: $dark_gray;
			    letter-spacing: 0;
        	}

        	.ginput_total {
			    font-size: 17px !important;
			    font-family: 'MontserratMedium';
			    color: $light_gray;
			    letter-spacing: 0;
        	}
        }

        .gform_footer {
        	position: relative;
        	text-align: right;
        	margin: 0 !important;
        	padding: 0 !important;
        	padding-top: 25.5px !important;

			&:after {
			    content: '\f105';
			    font-family: "FontAwesome";
			    position: absolute;
			    top: 50%;
			    right: 34px;
    			font-size: 16px;
    			color: $white;
			}
        }

        .gform_button {
        	position: relative;
            cursor: pointer;
			text-decoration: none;
			background: $black;
			color: $white;
			font-family: 'MontserratSemiBold';
			font-size: 13px;
			// padding: 14.5px 58.5px;
			padding: 14.5px 82.5px 14.5px 34.5px;
			border: none;
			border-radius: 0;
		    letter-spacing: 1px;
			margin: 0 !important;
			line-height: 22px !important;
        }

        .gform_ajax_spinner {
        	position: absolute;
        	right: 190px;
        	padding-top: 14px;
        }

        .gform_confirmation_message {
        	text-align: center;
        	margin-top: 40px;
		    font-size: 16px;
		    font-family: 'MontserratMedium';
		    color: $dark_gray;
		    letter-spacing: 0;
        }
	}
}
/** Gravity Form Layout **/



/** Modal/Reveal Subscribe Form **/
.reveal-overlay {
	z-index: 4005 !important;
}

.form-subscribe {
	h2 {
		text-align: center;
	    font-family: 'MontserratExtraBold';
	    font-size: 30px;
	    color: $black;
	    letter-spacing: 0;
	    line-height: normal;
	    margin: 25px 0 30px;
	}

	.gform_fields {
		grid-row-gap: 17px !important;
	}
	
    input.large, textarea.large {
        width: 100% !important;
        height: 46px;
		background: $white;
        color: $black;
        padding: 5px 18px !important;
        border: 1px solid $black;
        font-family: 'MontserratMedium';
        font-size: 15px !important;
        letter-spacing: 0;

        &::-webkit-input-placeholder {
            color: $black;
            font-family: 'MontserratMedium';
        }
        &:-ms-input-placeholder {
            color: $black;
            font-family: 'MontserratMedium';
        }
        &::-moz-placeholder {
            color: $black;
            font-family: 'MontserratMedium';
        }
        &:-moz-placeholder {
            color: $black;
            font-family: 'MontserratMedium';
        }  
    }

    .gform_footer {
    	position: relative;
    	text-align: right;
    	margin: 0 !important;
    	padding: 0 !important;
    	padding-top: 25.5px !important;
    }

    .gform_button {
    	position: relative;
        cursor: pointer;
		text-decoration: none;
		background: $black;
		color: $white;
		font-family: 'MontserratSemiBold';
		font-size: 14px;
		padding: 14.5px 37px;
		border: none;
		border-radius: 0;
	    letter-spacing: 1px;
		margin: 0 !important;
		line-height: 22px !important;
    }

    .gform_ajax_spinner {
    	position: absolute;
    	right: 190px;
    	padding-top: 14px;
    }

    .gform_confirmation_message {
    	text-align: center;
    	margin-top: 40px;
	    font-size: 16px;
	    font-family: 'MontserratMedium';
	    color: $dark_gray;
	    letter-spacing: 0;
    }
}



/** hide white margin bottom on footer 
additional css cause by gravity forms **/
#ui-datepicker-div {
    display: none;
}
