/* custom-media */

/* Large only */
// min width = '1,024px' and max width = '1,199px'
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
	/** header **/
	header {
		.page-slider-banner-wrapper {
			.slider-content-container {
				left: 15px;
			}
		}
	}
	/** header **/


	/** homepage **/
	main {
		&.home {
			.welcome-section {
				.welcome-content {
					h2 {
						margin-top: 0;
					}
				}
			}

			.whats-on-section {
				.swiper-custom-navigation {
					z-index: 3;

					.swiper-btn-prev {
						left: 0;
					}

					.swiper-btn-next {
						right: 0;
					}
				}
			}
		}
	}
	/** homepage **/


	/** footer **/
	footer {
		.sitemap-section {
			.footer-contact-details {
				right: 8px;
			}
		}
	}
	/** footer **/
}



/* Medium only */
// min width = '640px' and max width = '1,023px'
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
	/** header **/
	header {
		.page-slider-banner-wrapper {
			.slider-content-container {
				left: 15px;
			}
		}
	}
	/** header **/


	/** homepage **/
	main {
		&.home {
			.welcome-section {
				padding: 70px 0 85px;

				.welcome-content {
					text-align: center;
					margin-bottom: 50px;

					h2 {
						margin-top: 0;
						margin-bottom: 20px;
					}

					p {
						text-align: center;
					}
				}
			}

			.promotions-section {
				.__custom_padding {
					padding: 0 15px;
				}

				.promotion-item {
					margin-bottom: 40px;
				}
			}

			.whats-on-section {
				.__custom_padding {
					padding: 0 20px;
				}

				.swiper-custom-navigation {
					z-index: 3;

					.swiper-btn-prev {
						left: 0;
					}

					.swiper-btn-next {
						right: 0;
					}
				}
			}
		}
	}
	/** homepage **/


	/** footer **/
	footer {
		.sitemap-section {
			padding: 70px 0 50px;

			.__full_center_left {
				justify-content: center;
			}

			.__custom_padding {
				padding: 0 15px;
			}

			.footer-social-media {
				margin-bottom: 45px;
			}

			.footer-quick-links {
				text-align: center;
				margin-bottom: 40px;

				hr {
					margin: 13px auto 13px;
				}
			}

			.footer-opening-hours {
				text-align: center;
				margin-bottom: 40px;

				hr {
					margin: 13px auto 13px;
				}

				p {
					text-align: center;
				}		
			}

			.footer-contact-details {
				right: 0;
				text-align: center;
				margin-bottom: 40px;

				hr {
					margin: 13px auto 13px;
				}

				p {
					text-align: center;
				}	
			}

			.footer-subscribe-border {
				hr {
					margin: 17px 0 40px;
				}
			}

			.footer-subscribe-wrapper {
				display: block;
				text-align: center;
			}

			.footer-subscribe-details {
				text-align: center;
				margin-bottom: 20px;
				margin-top: 5px;
				top: 0;

				hr {
					margin: 13px auto 13px;
				}

				p {
					text-align: center;
				}	
			}
		}
	}
	/** footer **/


	/** Image Tiles Layout **/
	.image-tiles-section{
		.__tile_padding {
			padding: 0 15px;
		}
	}
	/** Image Tiles Layout **/


	/** Content And Media Editor Layout **/
	.content-and-media-section {
		.cam-content {
			text-align: center;
			margin-bottom: 50px;

			h2 {
				margin-bottom: 20px;
			}

			p {
				text-align: center;
			}
		}
	}
	/** Content And Media Editor Layout **/


	/** Gallery Menu & Details Layout **/
	.gallery-menu-details-section {
		.menu-content {
			margin: 0 auto 13.5px;
		}
	}
	/** Gallery Menu & Details Layout **/
}



/* Small only */
// min width = '320px' and max width = '639px'
@media screen and (min-width: 20em) and (max-width: 39.9375em) {
	/** header **/
	header {
		.page-slider-banner-wrapper {
			.slider-content-container {
				left: 15px;
			}
		}
	}
	/** header **/


	/** homepage **/
	main {
		&.home {
			.welcome-section {
				padding: 70px 0 85px;

				.welcome-content {
					text-align: center;
					margin-bottom: 50px;

					h2 {
						margin-top: 0;
						margin-bottom: 20px;
					}

					p {
						text-align: center;
					}
				}
			}

			.promotions-section {
				.__custom_padding {
					padding: 0 15px;
				}

				.promotion-item {
					margin-bottom: 40px;
				}
			}

			.whats-on-section {
				.__custom_padding {
					padding: 0 20px;
				}

				.swiper-custom-navigation {
					z-index: 3;

					.swiper-btn-prev {
						left: 0;
					}

					.swiper-btn-next {
						right: 0;
					}
				}
			}

			.home-gallery-section {
				.__inner_padding {
					padding: 0 10px;
				}

				.__m_bottom_large {
					margin-bottom: 0 !important;
				}

				.__m_bottom_small {
					margin-bottom: 0 !important;
				}

				.gallery-image-large {
					margin-bottom: 10px !important;
				}

				.gallery-image-small {
					height: 320px;
					margin-bottom: 10px !important;
				}
			}
		}
	}
	/** homepage **/


	/** footer **/
	footer {
		.sitemap-section {
			padding: 70px 0 50px;

			.__full_center_left {
				justify-content: center;
			}

			.__custom_padding {
				padding: 0 15px;
			}

			.footer-social-media {
				margin-bottom: 45px;
			}

			.footer-quick-links {
				text-align: center;
				margin-bottom: 40px;

				hr {
					margin: 13px auto 13px;
				}
			}

			.footer-opening-hours {
				text-align: center;
				margin-bottom: 40px;

				hr {
					margin: 13px auto 13px;
				}

				p {
					text-align: center;
				}		
			}

			.footer-contact-details {
				right: 0;
				text-align: center;
				margin-bottom: 40px;

				hr {
					margin: 13px auto 13px;
				}

				p {
					text-align: center;
				}	
			}

			.footer-subscribe-border {
				hr {
					margin: 17px 0 40px;
				}
			}

			.footer-subscribe-wrapper {
				display: block;
				text-align: center;
			}

			.footer-subscribe-details {
				text-align: center;
				margin-bottom: 20px;
				margin-top: 5px;
				top: 0;

				hr {
					margin: 13px auto 13px;
				}

				p {
					text-align: center;
				}	
			}
		}

		.copyright-section {
			padding: 15px 0;

			.footer-copyright {
				margin-bottom: 2px;

				p {
					text-align: center;
				}
			}

			.footer-designed {
				p {
					text-align: center;
				}
			}
		}
	}
	/** footer **/


	/** Events List Layout **/
	.events-list-section {
		.__event_padding {
			padding: 0 15px;
		}

		.__show_grid {
			margin-bottom: 30px;
		}
	}
	/** Events List Layout **/


	/** Image Tiles Layout **/
	.image-tiles-section{
		.__tile_padding {
			padding: 0 15px;
		}
	}
	/** Image Tiles Layout **/


	/** Gallery Layout **/
	.gallery-section {
		.__inner_padding {
			padding: 0 10px;
		}

		.__m_bottom_large {
			margin-bottom: 0 !important;
		}

		.__m_bottom_small {
			margin-bottom: 0 !important;
		}

		.gallery-image-large {
			margin-bottom: 10px !important;
		}

		.gallery-image-small {
			height: 320px;
			margin-bottom: 10px !important;
		}
	}
	/** Gallery Layout **/


	/** Content And Media Editor Layout **/
	.content-and-media-section {
		.cam-content {
			text-align: center;
			margin-bottom: 50px;

			h2 {
				margin-bottom: 20px;
			}

			p {
				text-align: center;
			}
		}
	}
	/** Content And Media Editor Layout **/


	/** Gallery Menu & Details Layout **/
	.gallery-menu-details-section {
		.gallery-image-item {
			margin-bottom: 15px;
		}

		.menu-content {
			margin: 0 auto 15px;
		}
	}
	/** Gallery Menu & Details Layout **/


	/** Modal/Reveal Newsletter Form **/
	.reveal.medium {
	    right: auto;
	    left: auto;
	    margin: 0 auto;
	    width: 95%;
	    height: auto;
	    min-height: auto;
	    top: 70px !important;
	}
	/** Modal/Reveal Newsletter Form **/
}



/* Large and up */
@media screen and (min-width: 64em) {

}

@media (max-width: 75em) {
	/** header **/
	header {
		.__desktop_logo_menu {
			display: none;
		}

		.__mobile_logo_menu {
			display: block;
		}

		.header-logo-section {
			display: none;
		}

		.header-menu-section {
			padding: 20px 0 25px;
		}
	}
	/** header **/
}



/* Medium and up */
@media screen and (min-width: 40em) {

}

@media (max-width: 900px) {

}

@media (max-width: 768px) {

}

@media (max-width: 667px) {

}



/***small devices***/
@media (max-width: 578px) {

}

@media (max-width: 530px) {

}

@media (max-width: 470px) {
	/** header **/
	header {
		.page-slider-banner-wrapper {
			.slider-content-wrapper {
				position: absolute;
				bottom: 56px;
				width: 100%;

				a {
					text-decoration: none;
					color: $dark_gray;
				}
			}

			.slider-content-container {
			    position: relative;
			    bottom: 0;
			    left: 0;
				margin: 0 auto;
				width: 370px;
			}

			.slider-content {
			    padding: 25px 65px 20px 25px;
			}
		}
	}
	/** header **/
}

@media (max-width: 430px) {
	.moby.left-side {
	    min-width: 320px;
	}
}

@media (max-width: 420px) {
 
}

@media (max-width: 411px) {

}

@media (max-width: 379px) {

}
