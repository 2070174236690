@font-face {
    font-family: 'OpenSansRegular';
    src: url('../fonts/OpenSans/OpenSans-Regular.ttf');
}
@font-face {
    font-family: 'OpenSansLight';
    src: url('../fonts/OpenSans/OpenSans-Light.ttf');
}
@font-face {
    font-family: 'MontserratRegular';
    src: url('../fonts/Montserrat/Montserrat-Regular.ttf');
}
@font-face {
    font-family: 'MontserratMedium';
    src: url('../fonts/Montserrat/Montserrat-Medium.ttf');
}
@font-face {
    font-family: 'MontserratSemiBold';
    src: url('../fonts/Montserrat/Montserrat-SemiBold.ttf');
}
@font-face {
    font-family: 'MontserratBold';
    src: url('../fonts/Montserrat/Montserrat-Bold.ttf');
}
@font-face {
    font-family: 'MontserratExtraBold';
    src: url('../fonts/Montserrat/Montserrat-ExtraBold.ttf');
}
@font-face {
    font-family: 'MontserratBlack';
    src: url('../fonts/Montserrat/Montserrat-Black.ttf');
}
@font-face {
    font-family: 'CircularStdBook';
    src: url('../fonts/Montserrat/CircularStd-Book.otf');
}



$primary-font: 'MontserratRegular', sans-serif;
$secondary-font: 'MontserratRegular', sans-serif;

body {
    &#tinymce{
        &.wp-editor {
            font-family: 'MontserratRegular' !important;
        }
    }
}

.mceContentBody.wp-editor {
    background-color: #b0b0b0;
}


.__opensans_regular {
    font-family: 'OpenSansRegular';
}

.__montserrat_regular {
    font-family: 'MontserratRegular';
}

.__montserrat_medium {
    font-family: 'MontserratMedium';
}

.__montserrat_semibold {
    font-family: 'MontserratSemiBold';
}

.__montserrat_bold {
    font-family: 'MontserratBold';
}

.__montserrat_extrabold {
    font-family: 'MontserratExtraBold';
}

.__montserrat_black {
    font-family: 'MontserratBlack';
}